import React from "react";
import DeveloperAPI from "../services/DeveloperAPIClient";
import Layout from "../components/layout/auth";
import { ErrorMessage, Form, Formik } from "formik";
import { FormErrorContainer } from "../components/forms";
import { Button, FormGroup, InputGroup } from "@blueprintjs/core";
import { Link } from "@reach/router";

const PasswordResetSubmitted = () => {
  return (
    <>
      <p>
        Please check your email for the reset link. If you do not receive it
        shortly please contact us at support@bite.ai
      </p>
      <Link to="/">
        <Button>Home</Button>
      </Link>
    </>
  );
};

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitSuccess: false,
      submitError: undefined
    };
    this.submit = this.submit.bind(this);
  }

  validate(values) {
    let errors = {};
    if (!values.email || values.email.trim() === "") {
      errors.email = "Email is a required field";
    }
    return errors;
  }

  async submit(values, actions) {
    try {
      await DeveloperAPI.forgotPassword(values.email);
      this.setState({ submitSuccess: true });
    } catch (theError) {
      this.setState({ submitError: theError.toString() });
    } finally {
      actions.setSubmitting(false);
    }
  }

  render() {
    /**
     * Public routes need to be in both the router and in stand alone files. Otherwise, what
     * happens is that the standalone pages won't be accessible from the router or by directly
     * going to the page
     * -- Vinay 11/20/2018
     */
    return (
      <Layout
        title="Forgot Password"
        footer={
          <Link to={"/login/"} style={{ marginTop: 40, color: "#AAA" }}>
            Back to Login.
          </Link>
        }
      >
        {this.state.submitSuccess ? (
          <PasswordResetSubmitted />
        ) : (
          <Formik
            initialValues={{ email: "" }}
            validate={this.validate}
            onSubmit={this.submit}
          >
            {({ isSubmitting, values, handleChange }) => (
              <Form style={{ width: 300 }}>
                <p style={{ textAlign: "center" }}>
                  Enter your email to receive an email with a link to reset your
                  password.
                </p>
                <FormGroup label={"Email"} labelFor={"email"}>
                  <InputGroup
                    placeholder={"email"}
                    large
                    id={"email"}
                    type={"email"}
                    autoComplete={"email"}
                    name={"email"}
                    value={values.email}
                    onChange={handleChange}
                  />
                </FormGroup>
                <ErrorMessage name="email" component={FormErrorContainer} />
                {this.state.submitError ? (
                  <div>{this.state.submitError}</div>
                ) : null}
                <Button large fill type="submit" disabled={isSubmitting}>
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </Layout>
    );
  }
}

export default ForgotPassword;
